<template>
  <div>
    <div class="wrapper">
      <nav>
        <div class="branding">Faylite</div>
        <div class="links">
          <ul>
            <li class="active">Home</li>
            <!--
            <li>Music</li>
            <li>Games</li>
            <li>Software</li>
            -->
          </ul>
        </div>
      </nav>
    </div>
    <div id="app">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  background: #212121;
  background: linear-gradient(135deg, #e91e63, #0275d8);
  color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;

  font-family: 'Asap', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#app {
  overflow: auto auto;
  height: 100%;
  width: 100%;
}

.wrapper {
  width: 75%;
  margin: 0 auto;
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  height: 100px;

  .branding {
    padding: 0;
    margin: 0;
    font-size: 36px;
    font-weight: bold;
    line-height: 100px;
  }

  .links {
    display: flex;
    justify-content: center;

    ul {
      display: flex;
      text-decoration: none;
      list-style: none;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-content: center;

      li {
        font-size: 24px;
        line-height: 60px;
        padding: 0 30px;
        user-select: none;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 300ms linear;

        &.active {
          border-bottom: 4px solid white;
          opacity: 1;
        }

        &:hover {
          opacity: 1;
        }

        &:active {
          color: darken(white, 10)
        }
      }
    }
  }
}
</style>

<template>
  <Head>
    <title>Faylite - Music, Games and Software</title>
    <meta name="description"
          content="Explore the latest free content from Faylite.
          Relax with free music streaming, experience new games within your browser, or explore other projects.
          All within your browser!"/>
    <meta name="copyright" content="Faylite" />
    <meta name="abstract" content="Overview of available content services." />
    <meta name="author" content="Faylite" />
    <meta name="keywords" content="music,songs,games,game,faylite,free,streaming,browser,browser games,content hub" />
    <meta name="Classification" content="Entertainment" />
  </Head>
  <div class="wrapper">
    <div class="hero">
      <h1>Hello, friend</h1>
      <p>
        Welcome to my central content hub, here you can explore
        the latest content that's been published for your enjoyment.
        Relax with the free music streaming service, or experience
        the latest game releases, all through your browser!
      </p>
    </div>
    <div class="sections">
      <a v-for="section in sections"
         :key="section.name"
         class="section"
         :href="section.leadsTo"
      >
        <h1>{{ section.name }}</h1>
        <p>{{ section.description }}</p>
      </a>
    </div>
  </div>
</template>

<script>
import { Head } from '@vueuse/head'

export default {
  name: 'Home',

  components: {
    Head
  },

  data: () => ({
    sections: [
      { name: 'Music', description: 'Relax', leadsTo: 'https://music.faylite.xyz/' },
      { name: 'Games', description: 'Experience', leadsTo: 'https://games.faylite.xyz/' }
    ]
  }),

  methods: {
    onSelect (section) {
      window.location.href = section.leadsTo
    }
  }
}
</script>

<style lang="scss" scoped>
.hero {
  margin-top: 140px;
  max-width: 600px;

  h1 {
    font-size: 60px;
    font-weight: bold;
  }

  p {
    font-size: 18px;
  }
}

.sections {
  margin-top: 50px;
  display: flex;
  flex-direction: row;

  @media (max-width: 700px) {
    flex-direction: column;

    .section {
      margin: 30px auto !important;
    }
  }

  .section {
    $sectionSize: 250px;
    $sectionGrowSize: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px;

    background: rgba(#E71E63, 0.15);
    border: 10px solid rgba(#F3F3F3, 0.05);
    border-radius: 100%;
    width: $sectionSize;
    height: $sectionSize;

    text-align: center;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    transition: all 250ms cubic-bezier(0.79, 0.24, 0.27, 0.95);
    color: white;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background: rgba(#E71E63, 0.45);
      transform: scale(1.1);
    }

    &:active {
      background: rgba(#E71E63, 0.55);
      transform: scale(0.95)
    }
  }
}
</style>
